import * as React from 'react'
import { graphql } from 'gatsby'
import { PrismicRichText } from '@prismicio/react'

export const Header = ({ slice }) => {
  const background_image = {backgroundImage: 'url(' + slice.primary.background_image.url + ')'};
  return (
	  <section className="bg-dark d-flex flex-column justify-content-center page-header" style={background_image}>
	  	<div className="container py-6 position-relative z-100">
	  		<div className="row">
	  			<div className="col-12 col-lg-7 text-white">
            <PrismicRichText field={slice.primary.headline.richText} />
	  				<PrismicRichText field={slice.primary.description.richText} />
	  				<a href={slice.primary.button_link.url} role="button" className="btn btn-primary btn-lg mt-4">{slice.primary.button_text.text}</a>
	  			</div>
	  		</div>
	  	</div>
	  	<div className="page-header-back"></div>
	  </section>
  )
}

export const query = graphql`
  fragment HomepageDataBodyHeader on PrismicHomepageDataBodyHeader {
    primary {
      headline {
        richText
      }
      description {
        richText
      }
      background_image {
        url
      }
			button_text {
        text
      }
      button_link {
        url
      }
    }
  }
  fragment PageDataBodyHeader on PrismicPageDataBodyHeader {
    primary {
      headline {
        richText
      }
      description {
        richText
      }
      background_image {
        url
      }
			button_text {
        text
      }
      button_link {
        url
      }
    }
  }
`
