import * as React from 'react'
import { graphql } from 'gatsby'
import { PrismicRichText } from '@prismicio/react'

export const HeadlineAndText = ({ slice }) => {
  return (
		<section>
			<div className="container py-6">
			  <PrismicRichText field={slice.primary.headline.richText} />
				<div className="mt-4">
					<PrismicRichText field={slice.primary.text.richText} />
				</div>
			</div>
		</section>
  )
}

export const query = graphql`
  fragment PageDataBodyHeadlineAndText on PrismicPageDataBodyHeadlineAndText {
    primary {
      headline {
        richText
      }
      text {
        richText
      }
    }
  }
`
