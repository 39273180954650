import { Header } from './Header'
import { Services } from './Services'
import { Breakfast } from './Breakfast'
import { Hall } from './Hall'
import { Reviews } from './Reviews'
import { Contacts } from './Contacts'
import { Footer } from './Footer'
import { NavbarSlice } from './Navbar'
import { HeadlineAndText } from './HeadlineAndText'
import { GeneralCard } from './GeneralCard'

export const components = {
  navbar: NavbarSlice,
  header: Header,
  services: Services,
  breakfast: Breakfast,
  hall: Hall,
  reviews: Reviews,
  footer: Footer,
  contacts: Contacts,
  headline_and_text: HeadlineAndText,
  general_card: GeneralCard,
}
