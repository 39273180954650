import * as React from 'react'
import { graphql } from 'gatsby'
import { PrismicRichText } from '@prismicio/react'

export const Reviews = ({ slice }) => {
  const reviews = slice.items.map((items, index) => {
    return (
      <div key={index} className="col-12 col-lg-6">
        <div className="bg-white soft-shadow d-flex p-3 h-100">
          <div className="d-flex flex-column justify-content-between">
            {items.user_image.url
            ? <div className="user-img-ph mb-2 d-flex justify-content-center align-items-center"><img className="side-img" src={items.user_image.url} /></div>
            : <div className="user-img-ph bg-primary-2 mb-2 d-flex justify-content-center align-items-center"><h3 className="text-white mb-0">{items.user_name.text.charAt(0)}</h3></div>
            }
            <div className="user-score d-flex justify-content-center align-items-center">{items.review_rating}</div>
          </div>
          <div className="ms-3">
            <PrismicRichText field={items.user_name.richText} />
            <PrismicRichText field={items.review_text.richText} />
            <a className="text-decoration-none" href={items.review_source_link.url} target="_blank"><h6 className="opacity-50 mb-0">{items.review_source.text}<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" className="bi bi-arrow-right-short mb-1" viewBox="0 0 16 16"><path fillRule="evenodd" d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8z"/></svg></h6></a>
          </div>
        </div>
      </div>
    )
  })

  return (
	  <section id={slice.primary.anchor.text} className="bg-light-2">
			<div className="container py-6 position-relative">
				<PrismicRichText field={slice.primary.headline.richText} />
        <div className="row mt-4 g-4">
          {reviews}
        </div>
			</div>
		</section>
  )
}

export const query = graphql`
  fragment HomepageDataBodyReviews on PrismicHomepageDataBodyReviews {
    primary {
      anchor {
        text
      }
      headline {
        richText
      }
    }
    items {
      user_name {
        richText
        text
      }
      user_image {
        url
      }
			review_text {
        richText
      }
			review_source{
        richText
        text
      }
			review_source_link{
        url
      }
      review_rating
    }
  }
`
