import * as React from 'react'
import { graphql } from 'gatsby'
import { PrismicRichText } from '@prismicio/react'
import instagramIcon from '../images/instagram.png'
import facebookIcon from '../images/facebook.png'

export const Footer = ({ slice }) => {
  const links = slice.items ? slice.items.map((items, index) => {
    return (
      <li key={index} className="list-inline-item">
        {(items.footer_link.url && items.footer_link_label.text) &&
          <a className="text-decoration-none" href={items.footer_link.url}><h6 className="text-white mb-0">{items.footer_link_label.text}</h6></a>
        }
      </li>
    )
  }) : null;
  return (
		<section>
      <div className="bg-footer">
				<div className="container py-3">
					<div className="d-flex row justify-content-between align-items-center">
						<div className="col-12 col-md-auto text-center my-3">
							<ul className="list-inline mb-0">
                {links}
                <li className="list-inline-item"><a className="text-decoration-none" href={slice.primary.impressum_link.url}><h6 className="text-white mb-0">{slice.primary.impressum_link_label.text}</h6></a></li>
							</ul>
						</div>
						<div className="col-12 col-md-auto text-center my-3">
							<ul className="list-inline mb-0">
                <li className="list-inline-item"><a href={slice.primary.facebook_link.url} className="d-flex justify-content-center align-items-center" style={{height:'40px',width:'40px',borderRadius:'50%',backgroundColor:'#fff'}}><img src={facebookIcon} alt="Facebook social icon" height="24" width="24"/></a></li>
                <li className="list-inline-item"><a href={slice.primary.instagram_link.url} className="d-flex justify-content-center align-items-center" style={{height:'40px',width:'40px',borderRadius:'50%',backgroundColor:'#fff'}}><img src={instagramIcon} alt="Instagram social icon" height="24" width="24"/></a></li>
							</ul>
						</div>
					</div>
				</div>
			</div>
			<div className="bg-dark-2">
				<div className="container py-4">
          <p className="text-white text-center mb-0">{slice.primary.copyright.text}</p>
				</div>
			</div>
		</section>
  )
}

export const query = graphql`
  fragment HomepageDataBodyFooter on PrismicHomepageDataBodyFooter {
    primary {
      copyright {
        text
      }
      facebook_link {
        url
      }
			instagram_link {
        url
      }
      impressum_link_label {
        text
      }
      impressum_link {
        url
      }
    }
    items {
      footer_link_label {
        text
      }
      footer_link {
        url
      }
    }
  }
  fragment PageDataBodyFooter on PrismicPageDataBodyFooter {
    primary {
      copyright {
        text
      }
      facebook_link {
        url
      }
			instagram_link {
        url
      }
      impressum_link_label {
        text
      }
      impressum_link {
        url
      }
    }
    items {
      footer_link_label {
        text
      }
      footer_link {
        url
      }
    }
  }
`
