import * as React from 'react'

import twitterIcon from '../images/twitter.png'
import instagramIcon from '../images/instagram.png'
import facebookIcon from '../images/facebook.png'

export const Footer = () => (
  <footer>
    
  </footer>
)
