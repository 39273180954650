import * as React from 'react'
import { graphql } from 'gatsby'
import { Helmet } from "react-helmet"

export const GeneralCard = ({ slice }) => {
  return (
		<div className="application">
      <Helmet>
        <meta charSet="utf-8" />
        <title>{slice.primary.title.text}</title>
        <meta name="description" content={slice.primary.description.text}/>
      </Helmet>
    </div>
  )
}

export const query = graphql`
  fragment HomepageDataBodyGeneralCard on PrismicHomepageDataBodyGeneralCard {
    primary {
      title {
        text
      }
      description {
        text
      }
    }
  }
  fragment PageDataBodyGeneralCard on PrismicPageDataBodyGeneralCard {
    primary {
      title {
        text
      }
      description {
        text
      }
    }
  }
`
