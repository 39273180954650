import * as React from 'react'
import { graphql } from 'gatsby'
import { PrismicRichText } from '@prismicio/react'

export const Contacts = ({ slice }) => {
  const timetable = slice.items.map((items, index) => {
    return (
      <li key={index} className="list-group-item">{items.timetable.text}</li>
    )
  })


  return (
    <section id={slice.primary.anchor.text}>
      <div className="container py-6">
        <div className="row">
          <div className="col-12 col-lg-5">
            <PrismicRichText field={slice.primary.headline.richText} />
            <PrismicRichText field={slice.primary.address.richText} />
            <PrismicRichText field={slice.primary.phone.richText} />
            <ul className="list-group list-group-flush h6 mt-3 timetable">
              {timetable}
            </ul>
            <a href={slice.primary.button_link_1.url} role="button" className="btn btn-primary mt-4 me-3">{slice.primary.button_text_1.text}</a>
            {(slice.primary.button_link_2.url && slice.primary.button_text_2.text) &&
              <a href={slice.primary.button_link_2.url} role="button" className="btn btn-secondary mt-4">{slice.primary.button_text_2.text}</a>
            }
          </div>
          <div id="map" className="col-12 col-lg-7 mt-4 mt-lg-0" dangerouslySetInnerHTML={{ __html: slice.primary.map_html }}>   
          </div>
        </div>
      </div>
    </section>
  )
}

export const query = graphql`
  fragment HomepageDataBodyContacts on PrismicHomepageDataBodyContacts {
    primary {
			anchor {
        text
      }
      headline {
        richText
      }
      address {
        richText
      }
      phone {
        richText
      }
			button_text_1 {
        text
      }
      button_link_1 {
        url
      }
      button_text_2 {
        text
      }
      button_link_2 {
        url
      }
      map_html
    }
    items {
      timetable {
        text
      }
    }
  }
  fragment PageDataBodyContacts on PrismicPageDataBodyContacts {
    primary {
			anchor {
        text
      }
      headline {
        richText
      }
      address {
        richText
      }
      phone {
        richText
      }
			button_text_1 {
        text
      }
      button_link_1 {
        url
      }
      button_text_2 {
        text
      }
      button_link_2 {
        url
      }
      map_html
    }
    items {
      timetable {
        text
      }
    }
  }
`
