import * as React from 'react'
import { graphql } from 'gatsby'
import { PrismicRichText } from '@prismicio/react'
import Carousel from 'react-bootstrap/Carousel';
import Accordion from 'react-bootstrap/Accordion';
import 'photoswipe/dist/photoswipe.css'
import 'photoswipe/dist/default-skin/default-skin.css'

import { Gallery, Item } from 'react-photoswipe-gallery'

export const Breakfast = ({ slice }) => {
  // return MyGallery()
  const galleryImages = slice.items.map((items, index) => {
    const imgUrl = items.gallery_image.url;
    return (
      <Carousel.Item key={index}>
          <Item
            key={index}
            original={imgUrl}
            thumbnail={imgUrl}
            width={2400}
            height={2400}
          >
            {({ ref, open }) => (
              <img
                ref={ref}
                onClick={open}
                src={imgUrl}
                alt="Teste"
                className="d-block w-100 carousel-img"
                // style={{ maxHeight: "320px", maxWidth: "320px", aspectRatio:"1", objectFit: "cover" }}
                // style={{ objectFit: "fill", maxWidth: '25%' }}
              />
            )}
          </Item>
      </Carousel.Item>
    );
  });

  return (
    <section id={slice.primary.anchor.text} className="bg-light-2">
      <div className="container py-6 position-relative">
        <PrismicRichText field={slice.primary.headline.richText} />
        <div className="mt-4 mb-5">
          <PrismicRichText field={slice.primary.description.richText} />
          { (slice.primary.button_link.url && slice.primary.button_text.text) &&
						<a href={slice.primary.button_link.url} role="button" className="btn btn-primary mt-3">{slice.primary.button_text.text}</a>
          }
        </div>
        <Gallery>
          <Carousel>
            {galleryImages}
          </Carousel>
        </Gallery>
        <div className="mt-4">
          <Accordion>
            <Accordion.Item eventKey="0">
              <Accordion.Header>{slice.primary.menu_title.text}</Accordion.Header>
              <Accordion.Body>
                <a href={slice.primary.menu_image.url} target="_blank"><img src={slice.primary.menu_image.url} className="side-img" /></a>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </div>
      </div>
    </section>
  )
}

export const query = graphql`
  fragment HomepageDataBodyBreakfast on PrismicHomepageDataBodyBreakfast {
    primary {
      anchor {
        text
      }
      headline {
        richText
      }
      description {
        richText
      }
      button_text {
        text
      }
      button_link {
        url
      }
      menu_title {
        text
      }
      menu_image {
        url
      }
    }
    items {
      gallery_image {
        url
      }
    }
  }
  fragment PageDataBodyBreakfast on PrismicPageDataBodyBreakfast {
    primary {
      anchor {
        text
      }
      headline {
        richText
      }
      description {
        richText
      }
      button_text {
        text
      }
      button_link {
        url
      }
      menu_title {
        text
      }
      menu_image {
        url
      }
    }
    items {
      gallery_image {
        url
      }
    }
  }
`
