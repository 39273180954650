import * as React from 'react'
import { graphql } from 'gatsby'
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import Container from 'react-bootstrap/Container';

export const NavbarSlice = ({ slice }) => {
  const links = slice.items ? slice.items.map((items, index) => {
    return (
      <li key={index} className="nav-item">
      {(items.navbar_link.text && items.navbar_link_label.text) &&
				  <Nav.Link className="fw-bolder me-4" href={items.navbar_link.text}>{items.navbar_link_label.text}</Nav.Link>
  }
  </li>
    )
  }) : null;
  return (
    <section className="bg-white">
      <Navbar expand="lg">
        <Container>
          <Navbar.Brand href="/"><img src={slice.primary.logo.url} className="side-img" width="160" /></Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="ms-auto">
            {links}
          </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </section>
  )
}

export const query = graphql`
  fragment HomepageDataBodyNavbar on PrismicHomepageDataBodyNavbar {
    primary {
      logo {
        url
      }
    }
    items {
      navbar_link_label {
        text
      }
      navbar_link {
        text
      }
    }
  }
  fragment PageDataBodyNavbar on PrismicPageDataBodyNavbar {
    primary {
      logo {
        url
      }
    }
    items {
      navbar_link_label {
        text
      }
      navbar_link {
        text
      }
    }
  }
`
