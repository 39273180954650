import * as React from 'react'
import { graphql } from 'gatsby'
import { PrismicRichText } from '@prismicio/react'

export const Hall = ({ slice }) => {
  return (
		<section id={slice.primary.anchor.text}>
			<div className="container py-6">
			<PrismicRichText field={slice.primary.headline.richText} />
				<div className="row mt-4">
					<div className="col-12 col-lg-6 order-1 order-lg-0">
						<PrismicRichText field={slice.primary.description.richText} />
            { (slice.primary.button_link.url && slice.primary.button_text.text) &&
						  <a href={slice.primary.button_link.url} role="button" className="btn btn-primary mt-3">{slice.primary.button_text.text}</a>
            }
					</div>
					<div className="col-12 col-lg-6 mb-3 mb-lg-0">
						<img src={slice.primary.side_image.url} className="side-img" />
					</div>
				</div>
			</div>
		</section>
  )
}

export const query = graphql`
  fragment HomepageDataBodyHall on PrismicHomepageDataBodyHall {
    primary {
			anchor {
        text
      }
      headline {
        richText
      }
      description {
        richText
      }
			button_text {
        text
      }
      button_link {
        url
      }
      side_image {
        url
      }
    }
  }
  fragment PageDataBodyHall on PrismicPageDataBodyHall {
    primary {
			anchor {
        text
      }
      headline {
        richText
      }
      description {
        richText
      }
			button_text {
        text
      }
      button_link {
        url
      }
      side_image {
        url
      }
    }
  }
`
