import * as React from 'react'
import { graphql } from 'gatsby'
import { PrismicRichText } from '@prismicio/react'

export const Services = ({ slice }) => {
  const services = slice.items.map((items, index) => {
    return (
      <div key={index} className="col-12 col-lg-4">
		  	<div className="icon-circle mb-3 d-flex justify-content-center align-items-center">
		  		<img src={items.service_icon.url} width="28" height="28" />
		  	</div>
		  	<PrismicRichText field={items.service_title.richText} />
		  	<PrismicRichText field={items.service_desc.richText} />
		  </div>
    )
  });

  return (
	  <section id={slice.primary.anchor.text} className="container py-6 position-relative">
			<PrismicRichText field={slice.primary.headline.richText} />
			<div className="row mt-4 gx-4">
				{services}
			</div>
		</section>
  )
}

export const query = graphql`
  fragment HomepageDataBodyServices on PrismicHomepageDataBodyServices {
    primary {
      anchor {
        text
      }
      headline {
        richText
      }
    }
    items {
      service_title {
        richText
      }
      service_desc {
        richText
      }
      service_icon {
        url
      }
    }
  }
`
